define(
    ["knockout", "knockout-validation"],
    function (ko) {
        var urlRegExp =
            /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.‌​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[‌​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1‌​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00‌​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u‌​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;
        var trimRegExp = /^\s+|\s+$/;
        ko.validation.defineLocale("ru-RU",
        {
            required: "Пожалуйста, заполните это поле",
            min: "Пожалуйста, введите число большее или равное {0}",
            max: "Пожалуйста, введите число меньшее или равное {0}",
            minLength: "Пожалуйста, введите по крайней мере {0} символов",
            maxLength: "Пожалуйста, введите не больше {0} символов",
            pattern: "Пожалуйста, проверьте это поле",
            step: "Значение должно быть кратным {0}",
            email: "Некорректный почтовый адрес",
            date: "Пожалуйста, введите правильную дату",
            dateISO: "Пожалуйста, введите правильную дату в формате ISO",
            number: "Пожалуйста, введите число",
            digit: "Пожалуйста, введите цифры",
            phoneUS: "Пожалуйста, укажите правильный телефонный номер",
            equal: "Значения должны быть равны",
            notEqual: "Пожалуйста, выберите другое значение",
            unique: "Пожалуйста, укажите уникальное значение"
        });
        ko.validation.locale("ru-RU");

        ko.validation.rules["password"] = {
            validator: function(str) {
                if (!str || str.length < 6)
                    return false;
                var hasDigit = false;
                var hasLower = false;
                var hasUpper = false;
                for (var i = 0; i < str.length; i++) {
                    var c = str.charAt(i);
                    if (c.match("[0-9]")) {
                        hasDigit = true;
                        continue;
                    }
                    if (c.match("[a-zа-я]")) {
                        hasLower = true;
                        continue;
                    }
                    if (c.match("[A-ZА-Я]")) {
                        hasUpper = true;
                        continue;
                    }
                };
                return hasDigit && hasLower && hasUpper;
            },
            message:
                "Пароль должен быть не короче 6 символов и содержать символы верхнего и нижнего регистров, а также цифры"
        };

        ko.validation.rules["confirmPassword"] = {
            getValue: function(o) {
                return (typeof o === "function" ? o() : o);
            },
            validator: function(val, otherField) {
                return val === this.getValue(otherField);
            },
            message: "Пароль и подтверждение не совпадают"
        };

        ko.validation.rules['url'] = {
            validator: function (val, validate) {
                if (!validate) { return true; }
                val = val.replace(trimRegExp, '');
                return ko.validation.utils.isEmptyVal(val) || val.match(urlRegExp);
            },
            message: 'Пожалуйста, введите корректную ссылку'
        };

        ko.validation.registerExtenders();
    });