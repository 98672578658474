define(
    [
        "scripts/main",
        "knockout",
        "sa-utils",
        "sa-ko-validation",
        "scripts/layout/auth-bindings"
    ],
    function(main, ko, utils) {
        var AuthViewModel = function(options) {
            var self = this;
            self.Templates = options.Templates;
            self.ReturnUrl = options.ReturnUrl;
            self.TemplateName = ko.observable();
            self.LoginProvider = options.LoginProvider;
            self.IsPopupOpen = ko.observable(!!options.ReturnUrl);
            self.IsPopupOpen.subscribe(function(val) {
                if (!val) {
                    self.setTemplate(self.Templates.Login);
                }
                ko.validation.group(self.LoginViewModel).showAllMessages(false);
                ko.validation.group(self.RegisterViewModel).showAllMessages(false);
                ko.validation.group(self.ForgotPasswordViewModel).showAllMessages(false);
                ko.validation.group(self.ExternalLoginNewUserViewModel).showAllMessages(false);
                ko.validation.group(self.ExternalLoginExistingUserViewModel).showAllMessages(false);
                ko.validation.group(self.EmailNotConfirmedViewModel).showAllMessages(false);
            });
            self.PopupClass = ko.pureComputed(function() {
                switch (self.TemplateName()) {
                case self.Templates.Login.Name:
                    return self.Templates.Login.Class;
                case self.Templates.Register.Name:
                    return self.Templates.Register.Class;
                case self.Templates.ForgotPassword.Name:
                    return self.Templates.ForgotPassword.Class;
                case self.Templates.ForgotPasswordConfirmation.Name:
                    return self.Templates.ForgotPasswordConfirmation.Class;
                case self.Templates.RegisterConfirmation.Name:
                    return self.Templates.RegisterConfirmation.Class;
                case self.Templates.ExternalLogin.Name:
                    return self.Templates.ExternalLogin.Class;
                case self.Templates.EmailNotConfirmed.Name:
                    return self.Templates.EmailNotConfirmed.Class;
                case self.Templates.ConfirmationEmailSent.Name:
                    return self.Templates.ConfirmationEmailSent.Class;
                default:
                    return self.Templates.Login.Class;
                }
            });

            self.LoginViewModel = ko.validatedObservable({
                Email: ko.observable()
                    .extend({
                        required: true
                    }),
                Password: ko.observable()
                    .extend({
                        required: true
                    }),
                RememberMe: ko.observable(false)
            });
            self.RegisterViewModel = ko.validatedObservable({
                Email: ko.observable()
                    .extend({
                        required: true,
                        email: true
                    }),
                Password: ko.observable()
                    .extend({
                        required: true,
                        password: true,
                        maxLength: 20
                    }),
                ConfirmPassword: ko.observable()
                    .extend({
                        required: true,
                        maxLength: 20
                    }),
                FirstName: ko.observable()
                    .extend({
                        required: true,
                        maxLength: 30
                    }),
                MiddleName: ko.observable()
                    .extend({
                        maxLength: 30
                    }),
                LastName: ko.observable()
                    .extend({
                        required: true,
                        maxLength: 30
                    })
            });
            self.RegisterViewModel()
                .ConfirmPassword.extend({
                    confirmPassword: self.RegisterViewModel().Password
                });
            self.ForgotPasswordViewModel = ko.validatedObservable({
                Email: ko.observable()
                    .extend({
                        required: true,
                        email: true
                    })
            });
            self.ExternalLoginNewUserViewModel = ko.validatedObservable({
                Email: ko.observable(options.Email)
                    .extend({
                        required: true,
                        email: true
                    }),
                FirstName: ko.observable(options.FirstName)
                    .extend({
                        required: true,
                        maxLength: 30
                    }),
                MiddleName: ko.observable(options.MiddleName)
                    .extend({
                        maxLength: 30
                    }),
                LastName: ko.observable(options.LastName)
                    .extend({
                        required: true,
                        maxLength: 30
                    })
            });
            self.ExternalLoginExistingUserViewModel = ko.validatedObservable({
                Email: ko.observable(options.Email)
                    .extend({
                        required: true
                    }),
                Password: ko.observable()
                    .extend({
                        required: true
                    })
            });
            self.EmailNotConfirmedViewModel = ko.validatedObservable({
                Email: ko.observable()
                    .extend({
                        required: true,
                        email: true
                    })
            });

            // Если в процессе логин через соцсеть, 
            // показываем окна для завершения регистрации
            if (options.LoginProvider) {
                self.setTemplate(self.Templates.ExternalLogin);
                self.IsPopupOpen(true);
            } else {
                self.setTemplate(self.Templates.Login);
            };
        }

        AuthViewModel.prototype.setTemplate = function(tmplInfo) {
            var self = this;
            self.TemplateName(tmplInfo.Name);
        }

        AuthViewModel.prototype.login = function() {
            var self = this;
            if (!self.LoginViewModel.isValid()) {
                ko.validation.group(self.LoginViewModel).showAllMessages();
                return;
            }
            utils.postJson.call(self,
                window.page.urls.Account.Login,
                self.LoginViewModel,
                function (result) {
                    if (result.notconfirmed) {
                        self.setTemplate(self.Templates.EmailNotConfirmed);
                    } else {
                        location.href = self.ReturnUrl ? self.ReturnUrl : window.page.urls.Search.Index;
                    }
                });
        };

        AuthViewModel.prototype.resetPassword = function() {
            var self = this;
            if (!self.ForgotPasswordViewModel.isValid()) {
                ko.validation.group(self.ForgotPasswordViewModel).showAllMessages();
                return;
            }

            utils.postJson.call(self,
                window.page.urls.Account.ForgotPassword,
                self.ForgotPasswordViewModel,
                function(result) {
                    self.setTemplate(self.Templates.ForgotPasswordConfirmation);
                });
        };

        AuthViewModel.prototype.register = function() {
            var self = this;
            if (!self.RegisterViewModel.isValid()) {
                ko.validation.group(self.RegisterViewModel).showAllMessages();
                return;
            }

            utils.postJson.call(self,
                window.page.urls.Account.Register,
                self.RegisterViewModel,
                function(result) {
                    self.setTemplate(self.Templates.RegisterConfirmation);
                });
        };

        AuthViewModel.prototype.externalNewUser = function() {
            var self = this;
            if (!self.ExternalLoginNewUserViewModel.isValid()) {
                ko.validation.group(self.ExternalLoginNewUserViewModel).showAllMessages();
                return;
            }

            utils.postJson.call(self,
                window.page.urls.Account.ExternalLoginNewUser,
                self.ExternalLoginNewUserViewModel,
                function(result) {
                    self.setTemplate(self.Templates.RegisterConfirmation);
                });
        }

        AuthViewModel.prototype.externalExistingUser = function() {
            var self = this;
            if (!self.ExternalLoginExistingUserViewModel.isValid()) {
                ko.validation.group(self.ExternalLoginExistingUserViewModel).showAllMessages();
                return;
            }

            utils.postJson.call(self,
                window.page.urls.Account.ExternalLoginExistingUser,
                self.ExternalLoginExistingUserViewModel,
                function(result) {
                    location.href = self.ReturnUrl ? self.ReturnUrl : window.page.urls.Search.Index;
                });
        }

        AuthViewModel.prototype.externalLogin = function(provider) {
            var self = this;
            var url = window.page.urls.Account.ExternalLogin + "?provider=" + provider;
            if (self.ReturnUrl) {
                url += "&returnUrl=" + self.ReturnUrl;
            }
            window.open(url, "_blank", "width=1000,height=550");
        }

        AuthViewModel.prototype.resendConfirmationEmail = function () {
            var self = this;

            utils.postJson.call(self,
                window.page.urls.Account.ResendConfirmationEmail,
                self.EmailNotConfirmedViewModel,
                function (result) {
                    self.setTemplate(self.Templates.ConfirmationEmailSent);
                });
        }

        AuthViewModel.prototype.close = function () {
            var self = this;
            self.IsPopupOpen(false);
        }

        return AuthViewModel;
    }
);