define(["jquery", "knockout", "jquery-placeholder", "magnific-popup", "styles/custom/magnific-popup-custom.css!"],
    function($, ko) {
        ko.bindingHandlers.modal = {
            update: function(element, valueAccessor) {
                var value = ko.unwrap(valueAccessor());
                var options = {
                    items: {
                        type: "inline",
                        src: $(element)
                    },
                    key: value.key,
                    removalDelay: 0,
                    mainClass: "mfp-fade",
                    tClose: "Закрыть",
                    callbacks: {
                        open: function() {
                            if (!value.show())
                                value.show(true);
                        },
                        close: function() {
                            if (value.show())
                                value.show(false);
                        }
                    }
                }
                if (value.show() === true) {
                    $.magnificPopup.open(options);
                    //костыль для поддержки плейсхолдеров на динамических шаблонах в ИЕ<10
                    $("input, textarea").placeholder();

                } else if (value.show() === false) {
                    $.magnificPopup.close();
                }
            }
        };
    });