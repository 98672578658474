define(["noty", "moment", "knockout", "jquery"],
    function(noty, moment, ko, $) {
        var displayMessage = function(message) {
            if (message) {
                noty({
                    text: message,
                    layout: "topCenter",
                    type: "success",
                    timeout: 5000,
                    theme: "relax"
                });
            }
        };

        var displayError = function(message) {
            if (message) {
                noty({
                    text: message,
                    layout: "topCenter",
                    type: "error",
                    timeout: 5000,
                    theme: "relax"
                });
            }
        };

        var handleAjaxError = function(xhr, status, error) {
            if (!xhr.getAllResponseHeaders()) {
                xhr.abort();
                if (window.isPageBeingRefreshed) {
                    return; // переход по ссылке и др., то есть не ошибка
                }
            }
            if (error)
                displayError(error);
            else
                displayError("Произошла ошибка!");
        };

        var checkAndHandleCustomError = function(data) {
            if (!data) {
                displayError("Произошла ошибка!");
                return true;
            } else {
                if (data.forbidden) {
                    displayError("Отказано в доступе!");
                    return true;
                }
                if (data.unauthorized || data.facadeDown) {
                    location.reload();
                }
                if (!data.ok) {
                    if (data.error) {
                        displayError(data.error);
                    }
                    else {
                        displayError("Произошла ошибка!");
                    }
                    return true;
                }
            }
            return false;
        }

        var utils = {};

        utils.displayMessage = displayMessage;

        utils.displayError = displayError;

        utils.handleAjaxError = handleAjaxError;

        utils.checkAndHandleCustomError = checkAndHandleCustomError;

        utils.isLastDayOfMonth = function(dt) {
            var test = new Date(dt.getTime());
            test.setDate(test.getDate() + 1);
            return test.getDate() === 1;
        }

        utils.getDateRangeString = function(startDate, endDate) {
            // getMonth возвращает месяц, начиная с 0
            // getDate возвращает день месяца, начиная с 1    
            var date;
            // Отсутствие левой границы (в том числе DateTime.MinValue)
            if (!startDate || !(startDate instanceof Date) || startDate.getFullYear() <= 1) {
                if (!!endDate && endDate instanceof Date) {
                    // По год.
                    if (endDate.getDate() === 1 && endDate.getMonth() === 0) {
                        return "не позже " + (endDate.getFullYear() - 1);
                    }
                    // По месяц.
                    if (endDate.getDate() === 1) {
                        date = new Date(endDate.getTime());
                        return moment(new Date(date.setMonth(date.getMonth() - 1))).format("не позже MMMM YYYY");
                    }
                    return moment(endDate).format("не позже MMMM YYYY");
                }
            }
            // Отсутствие правой границы
            else if (!endDate || !(endDate instanceof Date)) {
                if (!!startDate && startDate instanceof Date) {
                    // По год.
                    if (startDate.getDate() === 1 && startDate.getMonth() === 0) {
                        return moment(startDate).format("не раньше YYYY");
                    }
                    // По месяц.
                    if (startDate.getDate() === 1) {
                        return moment(startDate).format("не раньше MMMM YYYY");
                    }
                    return moment(startDate).format("не раньше MMMM YYYY");
                }
            } else {
                if (!!endDate && endDate instanceof Date) {
                    // Диапазон в день. Число месяц год.
                    date = new Date(startDate.getTime());;
                    if (endDate <= new Date(date.setDate(date.getDate() + 1))) {
                        return moment(startDate).format("DD MMMM YYYY");
                    }
                    // Диапазон в месяц. Месяц год.
                    date = new Date(startDate.getTime());;
                    if (endDate <= new Date(date.setMonth(date.getMonth() + 1))) {
                        if (endDate.getDate() === 1 || startDate.getMonth() === endDate.getMonth()) {
                            return moment(startDate).format("MMMM YYYY");
                        }
                    }
                    // Диапазон в год. Год.
                    date = new Date(startDate.getTime());;
                    if (endDate <= new Date(date.setFullYear(date.getFullYear() + 1))) {
                        if (endDate.getDate() === 1 && endDate.getMonth() === 0 ||
                            startDate.getFullYear() === endDate.getFullYear()) {
                            return moment(startDate).format("YYYY");
                        }
                    }
                    // Диапазон в несколько лет.
                    date = new Date(endDate.getTime());;
                    if (endDate.getDate() === 1 && endDate.getMonth() === 0) {
                        return moment(startDate).format("с YYYY") +
                            moment(new Date(date.setFullYear(date.getFullYear() - 1))).format(" по YYYY");
                    }
                    return "с " + moment(startDate).format("YYYY") + " по " + moment(endDate).format("YYYY");
                }
            }
            return "";
        }

        utils.getDictionaryString = function(dictionary, key) {
            var match = ko.utils.arrayFirst(dictionary || [],
                function(item) {
                    return item.Key === key;
                });
            if (match)
                return match.Value;
            else
                return "";
        }

        utils.getUserRoleName = function(role) {
            return utils.getDictionaryString(window.page.reference.UserRoles, role);
        }

        utils.getUserRolesString = function(roles) {
            var arr = $.map(roles || [],
                function(role) {
                    return utils.getUserRoleName(role);
                });
            arr = arr || [];
            return arr.join(", ");
        }

        utils.getDocumentTypeName = function(type) {
            return utils.getDictionaryString(window.page.reference.DocumentTypes, type);
        }

        utils.getIntersectionTypeName = function(type) {
            return utils.getDictionaryString(window.page.reference.IntersectionTypes, type);
        }

        utils.postJson = function(url, model, callback) {
            var self = this;
            if (ko.isObservable(self.IsLoading)) {
                self.IsLoading(true);
            }
            $.ajax(url,
                {
                    headers: window.page.token,
                    data: ko.toJSON(model),
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8"
                })
                .done(function(result) {
                    if (checkAndHandleCustomError(result)) {
                        return;
                    }
                    if (result.ok) {
                        if (typeof callback === "function") {
                            callback(result);
                        }
                    }
                })
                .fail(function(xhr, status, error) {
                    handleAjaxError(xhr, status, error);
                })
                .always(function() {
                    if (ko.isObservable(self.IsLoading)) {
                        self.IsLoading(false);
                    }
                });
        }

        // Приводим дату к Utc, сохраняя время
        utils.transformDateToUtc = function(date) {
            if (date && date instanceof Date) {
                return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()]).toDate();
            }
            return null;
        }
        utils.transformDateToUtcString = function(date) {
            if (date && date instanceof Date) {
                return moment.utc([date.getFullYear(), date.getMonth(), date.getDate()])
                    .format("YYYY-MM-DDTHH:mm:ss.sss") +
                    "Z";
            }
            return null;
        }

        // Для генерации токенов и т.п.
        utils.getRandomString = function (length) {
            var text = "";
            var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            length = length || 5;
            for (var i = 0; i < length; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        }

        utils.featureDetection = {};

        utils.featureDetection.xhr2 = (function() {
            return !!window.ProgressEvent && !!window.FormData;
        }());

        utils.featureDetection.history = (function() {
            return !!window.history && !!window.history.pushState;
        }());

        return utils;
    });